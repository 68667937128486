@import "../../sass/variables.scss";

.signup-progress {
  margin: 0px -13px 0px -12px;
  background-color: lighten($bits-color-green, 20);
}
.stepper-wrapper::after {
  border-bottom: 1px solid $bits-color-input-border-light;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 4px;
}
.stepper-wrapper {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.step-label {
  color: $bits-color-green;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 1.5;
  }
  &:first-child::before {
    content: none;
  }
  &:last-child::after {
    content: none;
  }
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    top: 4px;
    left: -50%;
    z-index: 2;
  }
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    top: 4px;
    left: 50%;
    z-index: 2;
  }
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $bits-color-light-blue;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
  bottom: 20px;
  white-space: nowrap;
  &:before {
    border-bottom: none;
  }
  &:after {
    border-bottom: none;
  }
}
.stepper-item.active .step-counter {
  background-color: $bits-color-green;
  width: 10px;
  height: 10px;
}
.stepper-item.completed .step-counter {
  background-color: $bits-color-green;
  width: 8px;
  height: 8px;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 1px solid $bits-color-green;
  width: 100%;
  top: 4px;
  left: 50%;
  z-index: 3;
}
