@import "../../../sass/variables";

.payment-date-input {
  color: $bits-color-disabled-grey;
  cursor: pointer;

  .form-control[readonly] {
    background-color: $bits-color-white;
    cursor: pointer;

    &::placeholder {
      color: $bits-color-disabled-grey;
      font-size: $bits-font-size-normal-text;
    }

    &:focus {
      border-color: $bits-color-input-border-dark;
    }
  }
}

.payment-date-calendar {
  border: 1px solid $bits-color-input-border-dark;
  border-radius: 5px;
  margin-top: -1px;

  .payment-date-btn {
    border-color: $bits-color-input-border-dark;
    color: $bits-color-black;
    font-size: $bits-font-size-tiny;
    line-height: 2rem;
    margin: 5px;
    width: 100%;
  }
}

.invisible {
  height: 20px !important;
}

#confirm-change-payment-date.btn-lg,
button#skip-change-payment-date {
  width: 100%;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 767px) {
  .payment-date-header {
    font-size: $bits-font-size-largest;
  }

  .payment-date-subheader {
    font-size: $bits-font-size-tiny;
  }

  .payment-date-input {
    font-size: $bits-font-size-tiny;

    .form-control[readonly] {
      font-size: $bits-font-size-tiny;

      &::placeholder {
        font-size: $bits-font-size-tiny;
      }
    }
  }
}
