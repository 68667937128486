@import "../../../sass/variables";

.upcoming-payments {
  &.has-unauthorized .modal-content {
    min-height: 513px;
  }

  .modal-content {
    padding-left: 30px;
    padding-right: 30px;

    min-height: 913px;

    @media only screen and (max-width: 767px) {
      min-height: 716px;
    }

    .modal-header {
      border-bottom: 0;
      padding: 30px 30px 0 0;
    }

    .modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;

      .header {
        font-size: $bits-font-size-modal-header;
        @media only screen and (max-width: 767px) {
          font-size: $bits-font-size-largest;
        }
        font-weight: 450;
        color: $bits-color-black;
        margin-bottom: 20px;
      }

      .payment-row {
        background-color: $bits-color-grey;
        border-radius: 10px;
        flex-grow: 0;
        padding: 25px 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: $bits-font-size-normal-text;

        @media only screen and (max-width: 767px) {
          font-size: $bits-font-size-small;
        }

        display: flex;
        .product-name {
          flex-grow: 1;
          .authorize-button-line {
            margin-top: 10px;
          }
        }

        .product-price {
          text-align: right;
        }
      }

      .dialog {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 100%;
        flex-grow: 1;

        .pending {
          display: flex;
          min-height: 100%;
          align-content: center;
          align-items: center;
          flex-direction: column;
        }

        .content {
          flex-grow: 1;
        }

        .pending-row {
          &.icon-row {
            align-content: flex-end;
            margin-bottom: 40px;
          }

          flex-grow: 1;
          .pending-message {
            font-size: $bits-font-size-normal-text;

            @media only screen and (max-width: 767px) {
              font-size: $bits-font-size-small;
            }
          }
        }

        .buttons {
          text-align: center;
          margin-bottom: 20px;

          button {
            width: 100%;
          }

          &:last-child {
            margin-bottom: 50px;
          }
        }
      }
      .overdue-text {
        color: $bits-color-red;
        font-weight: bolder;
        font-size: $bits-font-size-large;
      }
      .bill-label {
        color: $bits-color-black;
        background-color: $bits-color-white;
        padding: 0.1rem 0.5rem;
        border-radius: 7px;
      }
      .overdue-label {
        color: $bits-color-red;
        background-color: $bits-color-light-pink;
        padding: 0.1rem 0.5rem;
        border-radius: 7px;
      }
    }
  }
}
