.clock-img {
  text-align: center !important;
}

.plan-info {
  width: 75%;
}

@media (min-width: 992px) {
  .clock-img {
    text-align: right !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .plan-info {
    width: 100%;
    padding: 2px;
  }
  .clock-img {
    text-align: right !important;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .plan-info {
    width: 100%;
    padding: 2px;
  }
  .clock-img {
    text-align: center !important;
  }
  .experian {
    height: 25px;
  }
  .equifax {
    height: 18px;
  }
  .transunion {
    height: 18px;
  }
}