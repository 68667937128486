@import "../../sass/variables.scss";

.bits-content-wrapper {
  padding: 2rem 0;

  &.blue {
    background-color: $bits-color-very-light-blue;
  }
  &.white {
    background-color: $bits-color-light-grey;
  }
  &.red {
    background-color: $bits-color-alert;
  }
  &.top-shadow {
    box-shadow: inset 0 7px 9px -7px rgba(70, 70, 70, 0.25);
  }
  &.bits-blue {
    background-color: $bits-color-blue;
  }
}

.bits-content-wrapper:nth-of-type(1) {
  margin-top: $bits-top-nav-height;
}
