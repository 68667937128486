#kyc {
  .signup-header {
    border-bottom: 1px solid #D5D5D5;
    padding-left: 75px;
    padding-right: 75px;

  }

  .form-wrapper {
    padding: 0;
  }

  .body {

    .button {
      margin-top: 30px;
      margin-bottom: 20px;
    }

  }
}