@import "../../../../sass/variables.scss";

.subscription-card-holder {
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 100px !important;

  &:last-child {
    border-right: 0;
  }

  .card {
    padding: 88px 20px 0px 20px;

    .selected {
      color: #f2df35;
      font-size: 28px;
    }
  }

  .subscription-card {
    border-color: transparent;
    font-size: $bits-font-size-normal-text;
    min-height: 300px;
  }
  .current-plan {
    border-color: $bits-color-grey;
    font-size: $bits-font-size-normal-text;
    color: $bits-color-blue;
  }

  &:before {
    color: $bits-color-white;
    border: 0px;
    background-color: transparent;
  }

  .credit-limit {
    color: darken($bits-color-disabled-grey, 20%);
    font-weight: 600;
  }

  &.active {
    .subscription-card {
      border-color: $bits-palette-active-link;
    }

    &:before {
      border-color: $bits-color-blue;
      background-color: transparent;
      color: $bits-color-total-black;
    }
  }

  &:before {
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    width: 50%;
    text-align: center;
    left: 25%;
    margin-top: 30px;
    z-index: 1000;
    position: absolute;
    padding-bottom: 2px;
    white-space: nowrap;
    color: $bits-color-total-black;
    font-size: 25px;
    font-weight: 400;
  }
}

.subscription-card {
  cursor: pointer;
  background-color: $bits-palette-off-white-v4;
  border-radius: 12px;

  .subscription-card-header {
    background-color: $bits-color-background;
    border: 0;
    white-space: nowrap;
  }

  .row.plan-details {
    max-width: 240px;
    margin: 0 auto;
    padding-left: 25px;

    ul {
      padding: 0;
      text-align: left;

      li {
        color: $bits-color-light-purple;
        font-size: 16px;
        list-style: none;
        padding-left: 0px;
        margin-bottom: 15px;
        position: relative;

        &:after {
          content: "";
          background-image: url("../../../../assets/cassetteUI/icons/check.svg");
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: 0;
          left: -30px;
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}

.subscription-name {
  color: $bits-color-text;
  font-size: $font-size-title;
}

.check-icon {
  color: $bits-color-green;
  margin-right: 1rem !important;
}

.saving-label-annually {
  background-color: $bits-color-orange;
  color: $bits-color-black;
}

.saving-label-biannually {
  background-color: $bits-color-dark-grey;
  color: $bits-color-white;
}

.costco-offer {
  font-size: $bits-font-size-huge;
  text-align: center;
  color: $bits-color-blue;
  margin-top: 40px;
  transform: rotate(-12.51deg) translate(350px, 40px);
  img {
    margin-top: -15px;
    height: 80px;
  }
}

@media (max-width: 992px) {
  .subscription-card-holder {
    padding-right: 15px;
    padding-left: 15px;
    border-right: 0;

    .card {
      padding-bottom: 30px;

      .card-body {
        padding: 0;
      }

      .selected {
        font-size: 16px;
      }
    }
  }
  .costco-offer {
    font-size: $bits-font-size-fair;
    text-align: center;
    color: $bits-color-blue;
    margin-top: 10px;
    transform: rotate(-12.51deg) translate(80px, 40px);
    img {
      margin-top: -15px;
      height: 50px;
    }
  }
  .subscription-card {
    .row.plan-details {
      ul {
        padding: 0 1rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .subscription-card-holder {
    &:nth-child(3n) {
      border-right: 0;
    }
  }
}

@media (min-width: 1200px) {
  .subscription-card-holder {
    &:nth-child(4n) {
      border-right: 0;
    }
  }
}
.submit-button {
  width: 100% !important;
  background-color: $bits-palette-active-link !important;
  border-radius: 12px;
}
@media (min-width: 992px) {
  .saving-label-annually {
    background-color: $bits-color-orange;
    margin-left: -28px;
    margin-right: -28px;
  }
  .saving-label-biannually {
    background-color: $bits-color-dark-grey;
    color: $bits-color-white;
    margin-left: -28px;
    margin-right: -28px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .costco-offer {
    font-size: $bits-font-size-small;
    text-align: center;
    color: $bits-color-blue;
    margin-top: 10px;
    transform: rotate(-12.51deg) translate(-10px, 20px);
    img {
      margin-top: -15px;
      height: 40px;
    }
  }
}
.plan-price {
  font-family: $bits-font-hafferSQ-bold;
  color: $bits-color-total-black;
  font-size: 27px;
  letter-spacing: 0em;
}
.billed-monthly {
  font-family: $bits-font-hafferSQ-bold;
  color: $bits-color-light-purple;
  font-size: 19px;
  letter-spacing: 0em;
}
.plan-btn {
  position: absolute;
  bottom: -85px;
  left: 0;

  span {
    font-family: $bits-font-hafferSQ-bold;
    color: #ffffff;
    letter-spacing: 0em;

    &.currentPlan {
      font-size: 15px;
    }
    &.selectPlan {
      font-size: 20px;
    }
  }
}
.hideSelected {
  display: none;
}
.showSelected {
  display: block;
}
.description {
  max-width: 240px;
  margin: 0 auto;
  padding-left: 25px;

  p {
    color: #ffffff;
    font-size: 16px;
    text-align: left;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: -35px;
    }
  }
}
@media (max-width: 820px) {
  .plan-btn {
    bottom: -70px;
  }
}
