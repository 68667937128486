.mr-1 {
  margin-right: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}
