@import "../../sass/variables.scss";
.icon-row {
  justify-content: center;

  .icon {
    &.exclamationWarning {
      background-color: $bits-color-yellow;
    }
    &.exclamationError {
      background-color: $bits-color-red;
    }
    &.checkmark {
      background-color: $bits-color-green;
    }
    &.clockAlt {
      background-color: $bits-color-lighter-grey;
    }
    border: 0;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: $bits-color-white;
    font-size: 65px;
  }
}