@import "../../../sass/variables.scss";

.footer {
  width: 100%;
  height: auto;
  min-height: 100%;
  background-color: $bits-color-total-black !important;
  color: $bits-palette-white-faf9fb-v4 !important;

  a {
    text-decoration: none;
    color: $bits-palette-grey-bababa-v4 !important;
    &:hover {
      color: $bits-palette-grey-bababa-v4 !important;
      text-decoration: underline;
    }
    &:visited,
    &:focus {
      color: $bits-palette-grey-bababa-v4 !important;
    }
  }

  .copyright {
    font-size: $bits-font-size-small;
  }

  .container {
    background-color: $bits-color-total-black !important;
  }

  h3 {
    font-weight: 700;
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 22px;
    cursor: pointer;
  }

  p {
    letter-spacing: 0.016em;
    line-height: 26px;
  }

  span {
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
  }

  small {
    font-size: 16px;
    margin-bottom: 15px;
    text-align: left;
    letter-spacing: 0.016em;
    line-height: 26px;

    &:last-child {
      max-width: 100%;
    }

    &.address {
      width: 320px;
    }

    &.support {
      margin-bottom: 0px;
    }

    a {
      color: $bits-palette-white-faf9fb-v4 !important;
      &:hover {
        color: $bits-palette-white-faf9fb-v4 !important;
        text-decoration: underline;
      }
    }
  }

  .container {
    max-width: 100%;
    min-height: 292px;
    margin: 0 auto;
    padding: 0px 100px;
  }
  .row {
    padding: 53px 0px;
  }
  .row,
  .nestedRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .nestedRow {
    margin: 0 auto;
  }
  .nestedRow.menu {
    padding-bottom: 0;
  }

  .nestedRow.country-selector {
    padding-top: 0px;
  }
  .row:last-child {
    border-top: 1px solid rgba(255, 255, 255, 0.169);
  }
  .col {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .nestedCol {
    display: flex;
    flex-direction: column;
    margin-left: 40px;

    &:last-child {
      max-width: 215px;
    }

    .country-switch-wrapper {
      margin-top: 20px;
    }
  }
  .nestedCol a {
    margin-top: 10px;
  }
  @media (max-width: 1011px) {
    .nestedCol {
      margin-left: 60px;
    }
  }
  @media (max-width: 930px) {
    .nestedCol {
      margin-left: 20px;
    }
  }
  @media (max-width: 767px) {
    .row {
      padding: 35px 0px 15px 0px;
      text-align: left;
      flex-direction: column;
    }
    .col {
      width: 100%;
    }
    .nestedRow {
      flex-direction: column;
      align-items: center;
      margin: 0;
    }
    .nestedCol {
      margin: 20px 0px;
      padding: 0px;

      &:last-child {
        max-width: 100% !important;
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 50px;

    .container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 15px;
    }

    small {
      &:last-child {
        width: 100% !important;
      }
    }
  }
}
