@import "../../sass/variables.scss";

.form-label {
  margin-bottom: 0.1rem;
  font-size: 20px;
}

#signup-legal-name-form {
  @media only screen and (max-width: 375px) {
    #formYob {
      padding-right: 10px;
    }
  }
}

.payment-layout {
  background: $bits-color-light-grey;
  width: 918px;
  border-radius: 10px;
  padding: 20px 0;
  margin-left: auto;
  margin-right: auto;

  .signup-title {
    text-align: center;
    font-weight: 600;
    font-size: $bits-font-size-massive;
    line-height: 150%;
    letter-spacing: -0.5px;
    color: $bits-color-black;
  }

  .signup-subtitle {
    text-align: center;
    font-weight: 450;
    font-size: $bits-font-size-normal-text;
    line-height: 150%;
    letter-spacing: -0.5px;
    color: $bits-color-black;
  }
}

.hdpi.pac-logo:after {
  display: none;
}

.signup-address-suggestions {
  .btn {
    padding: 0.375rem 2rem;
    border-radius: 0.25rem;
  }

  .row:not(:first-of-type) {
    border-top: 1px solid $bits-color-light-blue;
  }
}
