@import "../../../sass/variables.scss";

header {
  &.header-container {
    padding: 28px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 16px 60px 16px;

    .sidebar-bits-logo-wrapper img {
      width: 90px;
    }

    .menu-items {
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        text-decoration: none;
        color: $bits-color-black;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.02em;

        &:hover {
          color: $bits-color-black !important;
        }

        &:last-child {
          font-family: $bits-font-hafferSQ-bold;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  header {
    display: none !important;
  }
}
