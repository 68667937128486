@import "./fonts.scss";

// Fonts
$font-size-title: $bits-font-size-large;
$font-size-subtitle: $bits-font-size-small;
$font-size-button-text: $bits-font-size-normal-ui;
$font-size-info-text: $bits-font-size-tiny;

// Refer branch guidelines for color
$bits-color-total-black: #000;
$bits-color-black: #4d5261;
$bits-color-black2: #2b2b2b;
$bits-color-blue: #82b4e1;
$bits-color-blue-alternate: #8fb4dd;
$bits-color-blue-outline: #306fd5;
$bits-color-blue-light: #829dff;
$bits-color-green: #96d2b6;
$bits-color-green-banner: #c3fff4;
$bits-color-red: #f88379;
$bits-color-pink: #f4c2d5;
$bits-color-yellow: #f3cb84;
$bits-color-orange: #ffd7a8;
$bits-color-orange-light: #f2df35;
$bits-color-cream: #f2f0eb;
$bits-color-grey: #e5e5e5;
$bits-color-white: #ffffff;
$bits-color-light-blue: #bfdaf3;
$bits-color-very-light-blue: #ecf2f9;
$bits-color-light-green: #90deba;
$bits-color-lighter-green: #a3d0b8;
$bits-color-light-red: #fcc1bc;
$bits-color-light-pink: #fae1ea;
$bits-color-light-cream: #f8f7f5;
$bits-color-dark-grey: #4f4f4f;
$bits-color-light-grey: #fafafa;
$bits-color-lighter-grey: #e2e2e2;
$bits-color-disabled-grey: #c2c2c2;
$bits-color-grey-alternate: #cecece;
$bits-color-shadow: #6f9ac5;
$bits-color-alert: #fae2e0;
$bits-color-light-purple: #a3a3be;
$bits-color-hover: #33aaff;

$bits-color-background: $bits-color-light-grey;
$bits-color-text: $bits-color-black;
$bits-color-active: $bits-color-blue;
$bits-color-inactive: $bits-color-grey;
$bits-color-text-btn-active: $bits-color-white;

$bits-color-input-border-dark: #b9b9b9;
$bits-color-input-border-light: #cadced;
$bits-color-input-error: #ff5252;
$bits-color-input-placeholder: #dbdbdb;
$bits-color-banner-black: #0c1e41;

$bits-top-nav-height: 60px;
$bits-color-logo: #eec4a5;
$bits-color-footer-link: #4b5161;
$bits-color-select: #f0f1fa;
$bits-color-select-gray: #908e8e;

// Cassette project dark theme
// $bits-palette-darker-grey: #29292e;
// $bits-palette-bright-green: #91f051;
// $bits-palette-dark-grey: #31313a;
// $bits-palette-color-light-blue: #758bff;
// $bits-improved-color-grey: #a3a3be;
// $bits-palette-color-ultra-dark-grey: #464649;
// $bits-palette-color-light-grey: #aeaeb2;
// $bits-palette-color-redish: #e27373;

// Cassette project light theme
$bits-palette-light-grey: #f5f6fa;
$bits-palette-active-link: #33aaff;
$bits-palette-lighter-grey: #e3e3e3;
$bits-palette-dark-grey: #757575;
$bits-palette-mid-grey: #a3a3be;
$bits-palette-light-blue: #758bff;
$bits-palette-color-light-grey: #aeaeb2;
$bits-palette-color-redish: #e27373;
$bits-palette-color-ultra-dark-grey: #464649;
$bits-palette-grey: #e8e8e8;
$bits-palette-graph-red: #ff735a;
$bits-palette-graph-orange: #ffa600c0;
$bits-palette-graph-blue: #72a1e0;
$bits-palette-graph-green: #84e584;
$bits-palette-graph-green-light: #ccff00;
$bits-palette-graph-grey: #e0e0e0;

// Cassette theme colours 4.0
$bits-palette-off-white-v4: #fafafa;
$bits-palette-light-green-v4: #f4f4f4;
$bits-palette-grey-v4: #e8e8e8;
$bits-palette-dark-grey-v4: #484848;
$bits-palette-orange-v4: #ff7200;
$bits-palette-yellow-v4: #f2df35;
$bits-palette-lime-green: #55cb7d;
$bits-palette-light-green: rgba(224, 250, 122, 0.25);
$bits-palette-mid-grey: #848383;
$bits-palette-light-grey: #cacaca;
$bits-palette-ultra-grey-v4: #dfdfdf;
$bits-palette-grad-green-v4: linear-gradient(90.18deg, rgba(72, 123, 32, 0.61) 0.15%, rgba(11, 106, 43, 0.51) 99.86%);
$bits-palette-off-white-light-v4: #f5f5f5;
$bits-palette-white-border-v4: #dadada;
$bits-palette-lightest-grey-v4: #f1f1f1;
$bits-palette-blue-v4: #6486ff;
$bits-palette-green-v4: #85ae84;
$bits-palette-bright-green-v4: #ccff00;
$bits-palette-lightest-grey-v4: #f5f5f5;
$bits-palette-ultra-light-grey-v4: #dcdcdc;
$bits-palette-light-grey-v4: #c3c3c3;
$bits-palette-text-grey-v4: #959595;

// Cassette signup/login theme colours 4.0
$bits-palette-transparent-white-v4: #ffffff21;
$bits-palette-lighter-grey-v4: #979797;
$bits-palette-grey-d5d5d5-v4: #d5d5d5;
$bits-palette-grey-d2d2d7-v4: #d2d2d7;
$bits-palette-grey-86868B-v4: #86868b;
$bits-palette-grey-787878-v4: #787878;
$bits-palette-grey-636363-v4: #636363;
$bits-palette-transparent-v4: transparent;
$bits-palette-bg-off-white-v4: #fdfefe;
$bits-palette-white-faf9fb-v4: #faf9fb;
$bits-palette-grey-bababa-v4: #bababa;
$bits-palette-grey-f9f9f9a1-v4: #f9f9f9a1;
$bits-palette-dark-grey-rgb-v4: rgba(0, 0, 0, 0.08);
$bits-palette-red-ff9898-v4: #ff9898;
$bits-palette-red-ffb5b5-v4: #ffb5b5;
$bits-palette-yellow-fffbd2-v4: #fffbd2;
$bits-palette-green-e6ffd2-v4: #e6ffd2;
$bits-palette-dark-blue-0b51c1-v4: #3183cc;
$bits-palette-grey-d9d9d9: #d9d9d9;
