@import url("https://use.typekit.net/sqv3bdn.css");
@import "./sass/variables.scss";

$theme-colors: (
  "default": $bits-color-black,
  "primary": $bits-color-blue,
  "success": $bits-color-green,
  "danger": $bits-color-red,
  "warning": $bits-color-yellow,
  "light": $bits-color-cream,
  "dark": $bits-color-black,
);

@import "node_modules/bootstrap/scss/bootstrap";

@import "./sass/theme.scss";
@import "./sass/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";
