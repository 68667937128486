@import "./variables.scss";
@import "./buttons.scss";

.form-control {
  border: 1px solid $bits-color-input-border-dark;
  box-sizing: border-box;
  border-radius: 4px;
  height: 50px;
  padding: 15px 20px;
  color: $bits-color-black;

  &::placeholder {
    color: $bits-color-input-placeholder;
  }

  &:valid {
    border-color: $bits-color-input-border-light;
  }

  &.is-invalid {
    border-color: $bits-color-input-error;
    background-image: none;
    border-radius: 4px !important;
    &:focus {
      box-shadow: none;
    }
  }

  &:active {
    border-color: $bits-color-input-border-dark;
  }

  &:focus {
    box-shadow: none;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  /*Sussie regular font weight 400*/
  font-weight: 400;
  line-height: 1.2;
}

h1 {
  font-size: $bits-font-size-massive;
}

h2 {
  font-size: $bits-font-size-huge;
}

h3 {
  font-size: $bits-font-size-larger;
}

h4 {
  font-size: $bits-font-size-large;
}

h5 {
  font-size: $bits-font-size-normal-text;
}

h6 {
  font-size: $bits-font-size-small;
}

p {
  font-size: $bits-font-size-normal-text;
}

.mt-6 {
  margin-top: 4rem !important;
}

.mt-7 {
  margin-top: 5rem !important;
}

.mt-8 {
  margin-top: 6rem !important;
}

.mt-9 {
  margin-top: 7rem !important;
}

.mt-10 {
  margin-top: 8rem !important;
}

.card {
  border-color: $bits-color-input-border-light;
  padding: 30px;
  .card-img,
  .card-img-top,
  .card-img-bottom,
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-radius: 10px;
  }
}

.card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
  border-radius: 10px;
}

.breadcrumb {
  .breadcrumb-item a {
    font-size: $bits-font-size-larger;
    color: $bits-color-disabled-grey;
    text-decoration: none;

    &:hover {
      color: $bits-palette-active-link !important;
    }

    .bi {
      font-size: 0.8em;
      margin-right: 0.5em;
    }
  }
}

form {
  .form-label {
    &.col-form-label {
      font-size: $bits-font-size-normal-text;

      &.col-form-label-lg {
        font-size: $bits-font-size-larger;
      }
      &.col-form-label-sm {
        font-size: $bits-font-size-small;
      }
    }
  }
}

.pac-container {
  z-index: 10000 !important;
}

@media only screen and (min-width: 768px) {
  .visible-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb {
    .breadcrumb-item a {
      font-size: $bits-font-size-normal-text;
      color: $bits-color-input-border-light;
    }
  }

  .hidden-mobile {
    display: none !important;
  }
}
