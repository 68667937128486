@import "../../sass/variables";

.store-breadcrumbs {
  .breadcrumb {
    .breadcrumb-item {
      max-width: 70%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      a {
        font-size: $bits-font-size-normal-text;
        font-weight: 400;
        color: $bits-color-disabled-grey;
      }

      &.active {
        font-size: $bits-font-size-normal-text;
        font-weight: 400;
        color: $bits-color-black;
      }
    }
  }

  .breadcrumb-item + .breadcrumb-item::before {
    color: $bits-color-disabled-grey;
    font-size: $bits-font-size-tiny;
    content: ">";
    padding: 6px 10px 0 4px;
  }

  @media only screen and (max-width: 767px) {
    .breadcrumb .breadcrumb-item a {
      color: $bits-color-input-border-light;
      font-size: $bits-font-size-normal-text;
    }
    .breadcrumb-item + .breadcrumb-item::before {
      display: none;
    }
  }
}