#apply-complete {
  .signup-header {
    border-bottom: 1px solid #D5D5D5;
    padding-left: 75px;
    padding-right: 75px;

  }
  .body {
    padding-left: 75px;
    padding-right: 75px;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.02em;
    button {
      margin-top: 110px;
    }
  }
}