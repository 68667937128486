@import "../../sass/variables.scss";

.cart-wrapper,
.checkout {
  .cart-container {
    display: flex;
  }

  .cart-go-to-checkout {
    min-width: 80%;
    margin-top: 67px;
  }

  #ac-payment {
    margin-top: 0px;
    border-radius: 10px;
  }

  #ac-shipping {
    border-radius: 10px;
  }

  .justify-content-right {
    display: flex;
    justify-content: flex-end;
  }

  #your-cart-title {
    background-color: $bits-color-very-light-blue;
    padding: 1rem;
    height: 72px;
    align-items: center;
    display: flex;
    border-radius: 10px;
    h1 {
      font-size: $bits-font-size-huge;
    }
  }

  .full-width {
    width: 100% !important;
  }

  .your-cart-header {
    padding-bottom: 23px;
  }

  .cart-no-items {
    font-size: $bits-font-size-big;
    margin-top: 56px;
  }

  .order-summary-line-container {
    display: flex;
  }

  .order-summary-line-label,
  .order-summary-line-value {
    font-size: $bits-font-size-normal-text;
    color: $bits-color-black;
  }

  .order-summary-line-value {
    justify-content: flex-end;
  }

  .order-line-row {
    margin-bottom: 27px;
  }

  .order-summary-line-shipping {
    border-bottom: 1px solid $bits-color-blue;
    padding-bottom: 43px;
    margin-bottom: 20px;
  }

  .order-summary-line-total-value {
    color: $bits-color-blue;
  }
  .cart-order-line {
    padding-top: 59.47px;
    border-top: 1px solid $bits-color-blue;
    margin-top: 10px;
    display: flex;
    .product-image {
      img {
        width: 80px;
        height: 80px;
        border-radius: 6px;
      }
    }
  }

  .order-line-row {
    flex-wrap: nowrap;
    display: flex;
  }

  .order-line-label-wrapper {
    width: 70%;
  }
  .order-line-value-wrapper {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 27px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 60px !important;

    .your-cart-header {
      display: none;
    }

    .cart-no-items {
      margin-top: 0px;
      margin-bottom: 23px;
      font-size: $bits-font-size-large;
    }

    div.product-quantity {
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 992px) {
    .cart-container {
      display: block;
    }
    div.product-quantity {
      display: flex;
      align-items: center;
      div.quantity-dropdown {
        margin: 0 10px 0 0;
        button {
          padding: 3px 1rem;
        }
      }
    }

    div.cart-go-to-checkout-wrapper {
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  .remove-cart-line {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.checkout {
  #your-cart_heading {
    font-size: $bits-font-size-large !important;
  }
}
.cart-modal-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 400px;
  transform: translate(-50%, -50%) !important;

  .your-cart-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: $bits-color-light-blue;
    border: none;
    padding: 10px 10px 0 0;
  }

  div.thumbnail {
    width: 139.55px;
    height: 161px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: $bits-color-white;
    margin-right: 18.2px;
    img {
      max-width: 78px;
      max-height: 148px;
      padding: 0 10px;
    }
  }

  div.product-title,
  div.product-amount,
  div.product-quantity {
    color: $bits-color-black;
    font-weight: 700;
    font-size: $bits-font-size-small;
  }

  div.product-amount {
    margin-top: 0.73px;
  }

  div.product-quantity {
    margin-top: 20.52px;
  }
}
.cart-modal {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0;
  min-width: 400px;

  .your-cart-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: $bits-color-light-blue;
    border: none;
    padding: 10px 10px 0 0;
  }

  div.thumbnail {
    width: 139.55px;
    height: 161px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: $bits-color-white;
    margin-right: 18.2px;
    img {
      max-width: 78px;
      max-height: 148px;
      padding: 0 10px;
    }
  }

  div.product-title,
  div.product-amount,
  div.product-quantity {
    color: $bits-color-black;
    font-weight: 700;
    font-size: $bits-font-size-small;
  }

  div.product-amount {
    margin-top: 0.73px;
  }

  div.product-quantity {
    margin-top: 20.52px;
  }
}

.cart-modal-body,
.cart-modal-footer {
  display: flex;
  justify-content: center;
  border: none;
  background-color: $bits-color-light-blue;
  color: $bits-color-black;
}

.modal-header {
  .btn-close {
    &:focus {
      box-shadow: none;
    }
  }
}
.cart-modal-footer {
  .buttons-wrapper {
    width: 70%;
    button {
      width: 100%;
      margin-bottom: 10px;
    }

    button.your-cart {
      padding: 10px;
    }
  }
}

.cart-timeout-wrapper {
  padding-top: 68px;
  .cart-timeout-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 3em;
      color: $bits-color-red;
    }
  }

  .cart-timeout-title,
  .cart-timeout-subtitle {
    margin-top: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: $bits-font-size-massive;
      color: $bits-color-black;
      text-align: center;
    }
  }

  .cart-timeout-subtitle {
    margin-top: 0px;
  }

  .cart-timeout-paragraph {
    margin-top: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: $bits-font-size-fair;
      color: $bits-color-black;
      text-align: center;
    }
  }

  .cart-timeout-retrieve-cart,
  .cart-timeout-visit-store {
    margin-top: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      min-width: 40%;
    }
    @media only screen and (max-width: 992px) {
      button {
        min-width: 80%;
      }
    }
  }

  .cart-timeout-visit-store {
    margin-top: 21px;
  }
}

.shopping-cart-icon {
  height: 34px;
  width: auto;
  margin: 0 !important;

  svg {
    height: 34px;
  }
}

.quantity-dropdown {
  margin: 0 0 0 10px;
  button {
    padding: 3px 1rem !important;
  }
}

.summary-container {
  height: 355px;

  h4,
  .product-name,
  .product-price {
    font-size: $bits-font-size-normal-text !important;
  }

  .product-image img {
    width: 70px;
    height: 70px;
  }

  &.scroll {
    overflow-y: scroll;
  }
}
