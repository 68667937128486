@import "../../../sass/variables";

.subscription-modal {

  #subscription-modal {
    width: 100%;
    max-width: 1320px;
  }

  .full-width {
    width: unset;
    position: unset;
    left: unset;
    right: unset;
    margin-left: unset;
    margin-right: unset;
    margin-bottom: unset;
  }

  .modal-content {
    padding-left: 30px;
    padding-right: 30px;

    .modal-header {
      border-bottom: 0;
      padding: 30px 30px 0 0;
    }

    .modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }
}