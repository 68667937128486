@import "../../../sass/variables.scss";

.subscription-card-holder {
  padding-right: 35px;
  padding-left: 35px;
  position: relative;

  &:last-child {
    border-right: 0;
  }

  .card {
    padding: 28px;
    padding-bottom: 35px;

    .card-body {
      padding: 0;
    }

    .selected {
      color: $bits-palette-yellow-v4;
      font-size: 28px;
    }
  }

  .subscription-card {
    border-color: $bits-color-grey;
    font-size: $bits-font-size-normal-text;
  }
  .current-plan {
    border-color: $bits-color-grey;
    font-size: $bits-font-size-normal-text;
    color: $bits-color-blue;
  }

  &:before {
    color: darken($bits-color-disabled-grey, 50%);
    border: 4px solid $bits-color-grey;
    background-color: $bits-color-grey;
  }

  .credit-limit {
    color: darken($bits-color-disabled-grey, 20%);
    font-weight: 600;
  }

  &.active {
    .subscription-card {
      border-color: $bits-color-blue;
    }

    &:before {
      border-color: $bits-color-blue;
      background-color: $bits-color-blue;
      color: $bits-color-white;
    }
  }

  &:before {
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    width: 50%;
    text-align: center;
    left: 25%;
    margin-top: -16px;
    z-index: 1000;
    position: absolute;
    padding-bottom: 2px;
    white-space: nowrap;
    font-weight: bold;
  }
}

.subscription-card {
  cursor: pointer;
  background-color: $bits-color-background;
  border: 4px solid $bits-color-black;
  border-radius: 12px;

  .subscription-card-header {
    background-color: $bits-color-background;
    border: 0;
    white-space: nowrap;
  }

  .row.plan-details {
    ul {
      padding: 0;
      text-align: left;

      li {
        font-size: $bits-font-size-tiny;
        list-style: disc;
        padding-left: 10px;
        padding-bottom: 5px;
      }
    }
  }
}

.subscription-name {
  color: $bits-color-text;
  font-size: $font-size-title;
}

.check-icon {
  color: $bits-color-green;
  margin-right: 1rem !important;
}

.saving-label-annually {
  background-color: $bits-color-orange;
  color: $bits-color-black;
}

.saving-label-biannually {
  background-color: $bits-color-dark-grey;
  color: $bits-color-white;
}

.costco-offer {
  font-family: $bits-font-covered-grace;
  font-size: $bits-font-size-huge;
  text-align: center;
  color: $bits-color-blue;
  margin-top: 40px;
  transform: rotate(-12.51deg) translate(350px, 40px);
  img {
    margin-top: -15px;
    height: 80px;
  }
}

@media (max-width: 992px) {
  .subscription-card-holder {
    padding-right: 15px;
    padding-left: 15px;
    border-right: 0;

    .card {
      padding: 10px;
      padding-bottom: 30px;

      .card-body {
        padding: 0;
      }

      .selected {
        font-size: 16px;
      }
    }

    &:before {
      border: 4px solid $bits-color-grey;
      background-color: $bits-color-grey;
      border-radius: 200px;
      padding-left: 10px;
      padding-right: 10px;
      width: 85px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      left: calc(50% - 40px);
      font-size: 14px;
      margin-top: -14px;
      z-index: 1000;
      position: absolute;
      padding-bottom: 2px;
      white-space: normal;
      font-weight: bold;
    }
  }
  .costco-offer {
    font-family: $bits-font-covered-grace;
    font-size: $bits-font-size-fair;
    text-align: center;
    color: $bits-color-blue;
    margin-top: 10px;
    transform: rotate(-12.51deg) translate(80px, 40px);
    img {
      margin-top: -15px;
      height: 50px;
    }
  }
  .subscription-card {
    .row.plan-details {
      ul {
        padding: 0 1rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .subscription-card-holder {
    &:nth-child(3n) {
      border-right: 0;
    }
  }
}

@media (min-width: 1200px) {
  .subscription-card-holder {
    &:nth-child(4n) {
      border-right: 0;
    }
  }
}
@media (min-width: 768px) {
  .submit-button {
    width: 50% !important;
  }
}
@media (min-width: 992px) {
  .saving-label-annually {
    background-color: $bits-color-orange;
    margin-left: -28px;
    margin-right: -28px;
  }
  .saving-label-biannually {
    background-color: $bits-color-dark-grey;
    color: $bits-color-white;
    margin-left: -28px;
    margin-right: -28px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .costco-offer {
    font-family: $bits-font-covered-grace;
    font-size: $bits-font-size-small;
    text-align: center;
    color: $bits-color-blue;
    margin-top: 10px;
    transform: rotate(-12.51deg) translate(-10px, 20px);
    img {
      margin-top: -15px;
      height: 40px;
    }
  }
}
