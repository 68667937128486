@import "../../sass/variables.scss";

.error-message {
  margin-top: $bits-top-nav-height;
  margin-bottom: 0;

  .alert {
    margin-bottom: 0;
  }
}

.bits-content-wrapper .error-message {
  margin-top: 0;
}