#data-confirm {
  .signup-header {
    border-bottom: 1px solid #D5D5D5;
    padding-left: 75px;
    padding-right: 75px;

  }
  .body {
    padding-left: 75px;
    padding-right: 75px;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.02em;

    ul {
      margin-top: 40px;
      margin-bottom: 60px;
      padding-left: 0;
      li {
        list-style: inside;
        margin-bottom: 20px;
        margin-top: 10px;
      }
    }

    .bottom-message {
      margin-top: 40px;
      color: #AC9100;
      i {
        font-size: 32px;
      }
      p {
        color: #AC9100;
      }
      .text {
        font-size: 20px;
        padding-left: 23px;
      }
    }
  }
}