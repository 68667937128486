@import "../../../sass/variables.scss";

.order-line {
  .flex-row {
    display: flex;
  }

  padding: 20px;
  align-items: center;
  display: block;
  @media only screen and (max-width: 992px) {
    padding: 8px;
  }

  .product-description {
    flex-grow: 1;
    display: block;
    align-items: center;

    .product-name,
    .product-shipping {
      padding-left: 33px;
      padding-right: 5px;
      font-size: $bits-font-size-larger;
      text-align: left;
      flex-grow: 1;
      @media only screen and (max-width: 992px) {
        padding-left: 15px;
        font-size: $bits-font-size-small;
      }
    }

    .product-price {
      font-size: $bits-font-size-huge;
      color: $bits-color-blue;
      @media only screen and (max-width: 992px) {
        font-size: $bits-font-size-fair;
      }
    }
  }

  .product-image {
    img {
      width: 116px;
      border-radius: 6px;
      @media only screen and (max-width: 992px) {
        width: 70px;
      }
    }
  }
  .row-border-bottom {
    border-bottom: 1px solid $bits-color-blue;
    padding-left: 0px;
    height: 70px;
  }
}

.totals {
  padding-right: 30px;

  @media only screen and (max-width: 767px) {
    padding-right: 18px;
  }

  .totals-name {
    flex-grow: 1;
    font-size: $bits-font-size-fair;
    font-weight: 600;
    text-align: left;
    @media only screen and (max-width: 992px) {
      font-size: $bits-font-size-small;
    }
  }

  .totals-price {
    font-size: $bits-font-size-fair;
    @media only screen and (max-width: 992px) {
      font-size: $bits-font-size-small;
    }
  }

  &.last {
    @media only screen and (max-width: 767px) {
      padding-bottom: 9px;
    }
  }

  &.grand-total {
    color: $bits-color-blue;
    border-radius: 5px;
  }

  &.grand-total-border {
    color: $bits-color-blue;
    border-radius: 5px;
    height: 70px;
    vertical-align: middle;
    border-bottom: 1px solid $bits-color-blue;
    border-top: 1px solid $bits-color-blue;
  }

  &.rol-space-top {
    height: 70px;
    vertical-align: bottom;
  }
  &.rol-space-bottom {
    height: 70px;
  }
}
.row-devider {
  height: 20px;
}
.credit-total {
  border-bottom: 1px solid $bits-color-blue;
  padding-bottom: 20px;
  padding-top: 20px;
}

.shopping-cart {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 10px;
  background-color: $bits-color-white;
  .col-border-bottom {
    border-bottom: 1px solid $bits-color-blue;
  }
}

.order-summary-line-wrapper {
  padding: 0px 10px;
}
@media (max-width: 767px) {
  .shopping-cart {
    padding-left: 20px;
    order: -1;
  }
}
