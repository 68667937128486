@import "../../sass/variables.scss";

.footer {
  .country-switch-title {
    font-size: $bits-font-size-small;
  }

  .dropDown {
    position: relative;
    width: 190px;
    height: 39px;
    border-radius: 9px;
    border: 1px solid $bits-color-select-gray;
    margin-right: 64px;
    padding: 0px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }

  .dropDown span.location {
    font-weight: 500;
    color: $bits-color-select-gray;
    font-size: 14px;
    letter-spacing: 0px;
    margin-left: 7px;
    text-transform: capitalize;
  }

  .select {
    width: 190px;
    height: 40px;
    position: absolute;
    top: 39px;
    left: 0px;
    padding: 10px 15px;
    background-color: $bits-color-select;
    transition: all 0.3s;
    z-index: 999;
    display: flex;
    flex-direction: column;
    border-radius: 9px;
  }

  .select span {
    font-weight: 500 !important;
    color: $bits-color-select-gray !important;
    font-size: 14px !important;
    letter-spacing: 0px !important;
    cursor: pointer !important;
    margin-bottom: 10px !important;
    text-transform: capitalize;
  }

  .chevronDown {
    position: absolute;
    right: 10px;
  }

  .closeButton {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    .dropDown {
      margin-right: 20px;
    }
  }
  @media (max-width: 500px) {
    .dropDown {
      margin-right: 0px;
    }
  }
}
