@import "./variables.scss";
@import "./mixins.scss";
@import "./fonts.scss";
@import "./spacing.scss";
body {
  font-family: $bits-font;
  background-color: $bits-color-very-light-blue;
  color: $bits-color-black;
  &.loggedIn {
    background-color: $bits-color-white;
  }
  &.cassette {
    font-family: $bits-font-hafferSQ-regular;
    line-height: 24px;
    letter-spacing: -0.01em;
    div {
      &.modal-backdrop {
        opacity: 0.75 !important;
      }
    }
  }
  &.loggedOut {
    background-color: $bits-color-white;
  }
  #superChargeModal {
    &.modal-dialog {
      margin: 0px;
      min-height: auto;
      transform: none !important;
      .modal-content {
        border: 0px;
        border-radius: 0px;
        background-color: transparent !important;
      }
    }
  }
}

a {
  color: $bits-color-blue;
  &:hover {
    color: darken($bits-color-blue, 20%) !important;
  }
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-tel-input {
  input {
    padding: 15px 20px;
    height: auto !important;
  }

  input:focus {
    border-color: $bits-color-blue;
  }
}

.info-message-wrapper {
  padding: 10px;
  background: rgba(226, 226, 226, 0.5);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

li {
  list-style: none;
}

.pre-white-space {
  white-space: pre;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: $bits-color-green;
  box-shadow: 0 0 0 0.25rem rgba(150, 210, 182, 0.25);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: $bits-color-input-error;
  box-shadow: 0 0 0 0.25rem rgba(255, 82, 82, 0.25);
}
@media only screen and (max-width: 767px) {
  .hide-imgages img {
    display: none !important;
  }
}

.was-validated .form-control:valid {
  background-image: none !important;
}

.invalid-feedback {
  color: $bits-color-input-error;
}
