@import "../../../sass/variables";

.account-setup {

  .modal-content {
    padding-left: 30px;
    padding-right: 30px;

    .loading-container {
      margin-top: 318px;
    }

    .loading-message {
      margin-top: 73px;
      margin-bottom: 318px;

      .col {
        font-weight: 400;
        color: $bits-color-black;
        text-align: center;
        font-size: $bits-font-size-modal-subheader;
      }
    }

    .modal-header {
      border-bottom: 0;
      padding: 30px 30px 0 0;
    }

    .header {
      font-weight: 400;
      font-size: $bits-font-size-modal-header;
      color: $bits-color-black;
      text-align: center;
      margin-bottom: 29px;

      &.step3 {
        margin-bottom: 47px;
      }

      @media only screen and (max-width: 767px) {
        font-size: $bits-font-size-large;
      }
    }

    .subheader {
      font-weight: 400;
      font-size: $bits-font-size-modal-subheader;
      color: $bits-color-black;
      text-align: center;
      margin-top: 30px;

      @media only screen and (max-width: 767px) {
        font-size: $bits-font-size-normal-text;
      }

      &.last {
        margin-bottom: 81px;
        @media only screen and (max-width: 767px) {
          margin-bottom: 20px;
        }
      }

      &.step3 {
        margin-bottom: 62px;
      }

      &.small {
        font-size: $bits-font-size-normal-text;
        text-align: left;
      }
    }

    .image {
      img {
        border-radius: 10px;
      }

      text-align: center;
      margin-bottom: 59px;
    }

    .notes {
      font-weight: 400;
      font-size: $bits-font-size-normal-text;
      color: $bits-color-total-black;
      margin-top: 41px;
      @media only screen and (max-width: 767px) {
        font-size: $bits-font-size-small;
        margin-top: 21px;
      }

      li {
        list-style-type: none;
        margin-bottom: 24px;
        @media only screen and (max-width: 767px) {
          margin-bottom: 10px;
        }

        &::before {
          content: "";
          display: inline-block;
          width: 17px;
          height: 17px;
          color: $bits-color-black;
          background-color: $bits-color-black;
          margin-left: -30px;
          border-radius: 17px;
          margin-right: 24px;
          margin-bottom: -2px;
          @media only screen and (max-width: 767px) {
            width: 10px;
            height: 10px;
          }
        }
      }
    }

    .agreement {
      .checkbox {
        display: inline-block;
        margin-left: 4px;

        margin-right: 26px;
      }

      font-size: $bits-font-size-normal-text;
      @media only screen and (max-width: 767px) {
        font-size: $bits-font-size-small;
      }

      .disabled {
        color: $bits-color-disabled-grey;
      }

      .enabled {
        color: $bits-color-light-green;
      }
      margin-bottom: 77px;
    }

    .icon-row {
      margin-top: 106px;
      margin-bottom: 102px;
      @media only screen and (max-width: 767px) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .step3 {
      .icon-row {
        margin-top: 75px;
        margin-bottom: 47px;
        @media only screen and (max-width: 767px) {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }

      .buttons {
        button {
          font-size: $bits-font-size-big-button !important;
        }
      }
    }

    .buttons {
      text-align: center;
      margin-bottom: 95px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 30px;
      }

      button {
        width: 100%;
        color: $bits-color-white !important;
        font-size: $bits-font-size-larger;
      }

      &:last-child {
        margin-bottom: 50px;
      }
    }

    .modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }
}