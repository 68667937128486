@import "../../../sass/variables.scss";
@import "../../../sass/fonts.scss";
.variant-information {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 33px;
  margin-top: 1em;
  @media only screen and (max-width: 767px) {
    margin-top: 0;
  }
  .variant-feature {
    legend {
      float: unset;
      font-size: $bits-font-size-tiny;
      margin: 0 5px 0 0;
      line-height: 0px;
    }
    padding: 7px 15px 5px 15px;
    margin-right: 1em;
    background: $bits-color-white;
    border: 1px solid $bits-color-input-border-light;
    border-radius: 6px;
    text-align: center;
    .variant-feature-label {
      color: $bits-color-blue;
    }
  
    .variant-feature-value {
      color: $bits-color-black;
      font-size: $bits-font-size-normal-ui;
      @media only screen and (max-width: 992px) {
        font-size: $bits-font-size-small;
      }
    }
  }
}
