#address-confirm {
  .signup-header {
    border-bottom: 1px solid #D5D5D5;
    padding-left: 75px;
    padding-right: 75px;

  }
  .body {
    padding-left: 75px;
    padding-right: 75px;

    .address-container {
      margin-bottom: 50px;
      border: 2px solid #D2D2D7;
      border-radius: 25px;

      font-style: normal;
      font-size: 24px;
      line-height: 26px;
      letter-spacing: -0.02em;
      text-align: left;
      color: #000000;
      font-weight: normal;
      .title {
        font-weight: 700;
      }

      padding: 33px 29px;
    }

    .bottom-message {
      margin-top: 40px;
      color: #AC9100;
      i {
        font-size: 32px;
      }
      p {
        color: #AC9100;
      }
      .text {
        font-size: 20px;
        padding-left: 23px;
      }
    }
  }
}