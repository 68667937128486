@import "../../../sass/variables.scss";
@import "../../../sass/fonts.scss";

.unAuth-card-plans {
  position: relative;
  max-width: 100%;
  width: 1216px;
  min-height: 507px;
  border-radius: 12px;
  margin: 0px auto 57px auto;

  .plans-header {
    text-align: left;
    margin-bottom: 100px;

    h5 {
      font-family: $bits-font-hafferSQ-bold;
      font-size: 24px;
      line-height: 26px;
      color: $bits-color-total-black;
    }

    p {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.03em;
      color: $bits-palette-dark-grey-v4;
    }
  }
}
.unAuth-card {
  width: 572px;
  max-width: 100%;
  height: auto;
  background: $bits-palette-transparent-white-v4;
  border-radius: 24px;
  margin: 0 auto;
  margin-bottom: 33px;
  padding: 50px 0px 17px;

  .signup-header {
    padding: 20px 15px;
    text-align: center;

    h5 {
      &.title {
        font-family: $bits-font-hafferSQ-bold;
        font-size: 24px !important;
        line-height: 26px;
        color: $bits-color-total-black;
        margin-bottom: 15px;
      }
      &.legal {
        max-width: 260px;
        margin: 0 auto;
      }
      &.address {
        max-width: 300px;
        margin: 0 auto;
      }
    }
    p {
      &.subtitle {
        font-weight: 400;
        max-width: 305px;
        font-size: 20px;
        line-height: 23px;
        margin: 0 auto;
        color: $bits-palette-grey-787878-v4;
      }
    }
    a {
      &.data-privacy {
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.03em;
        color: $bits-palette-blue-v4;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .form-wrapper {
    text-align: left;
    padding: 40px 64px 33px 64px;

    .address-confirmation {
      border: 1px solid #d2d2d7;
      border-radius: 25px;
      padding: 16px;
    }

    h2 {
      font-weight: 600;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: -0.03em;
      color: $bits-color-total-black;
    }

    p {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.03em;
      color: $bits-palette-dark-grey-v4;
    }

    .form-group {
      height: 160px;
      position: relative;
    }

    label {
      &.form-label {
        margin-bottom: 10px;
        color: $bits-palette-dark-grey-v4;
      }
    }
    .form-input {
      width: 100%;
      max-width: 100%;
      height: 61px;
      background-color: $bits-palette-dark-grey-rgb-v4 !important;
      border: 0px;
      border-radius: 18px !important;
      color: $bits-color-total-black;
      transition: all 0.5s;
      padding: 0px 20px;
      font-size: 18px;

      &::placeholder {
        color: $bits-color-total-black;
      }
      &:focus {
        outline: none;
      }
    }
    .form-select {
      width: 100%;
      max-width: 100%;
      height: 61px;
      background-color: $bits-palette-dark-grey-rgb-v4 !important;
      border: 0px;
      border-radius: 18px !important;
      transition: all 0.5s;
      padding: 0px 20px;
      font-size: 18px;
      color: $bits-color-total-black !important;
    }

    input[type="button"],
    input[type="submit"],
    button {
      &.form-input {
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px !important;
        transition: all 0.7s;
        cursor: not-allowed;
        width: 100%;
        border: 0px;
        background-color: $bits-color-total-black !important;
        opacity: 0.5;
        &.enabled {
          color: $bits-color-white !important;
          cursor: pointer;
          opacity: 1;
        }
      }
    }
    button {
      &.form-input {
        border-radius: 50px !important;
        color: $bits-color-white !important;
      }
    }

    /*Cal icon*/
    .input-icon {
      height: 100%;
      position: absolute;
      top: 0;
      right: 20px;
      display: flex;
      align-items: center;
    }
  }
}

.login-page-switch {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 32px;

  .btn-switch {
    a {
      width: 100% !important;
      text-decoration: none;
      color: $bits-color-black;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: -0.02em;
      font-family: $bits-font-hafferSQ-bold;
    }
  }
}
.termsConditions {
  max-width: 412px;
  color: $bits-palette-grey-636363-v4;
  margin: 0 auto;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.02em;

  a {
    color: $bits-palette-grey-636363-v4;
  }
}

/*over ride style*/
#change-password-container .password-toggle {
  top: 0px !important;
  bottom: 0px;
  display: flex;
  align-items: center;
}

/*cassette main class - used to override classes*/
.cassette {
  .form-control {
    height: 61px;
    background-color: $bits-palette-lightest-grey-v4;
    border-radius: 12px !important;
    color: $bits-palette-light-grey-v4;
    transition: all 0.5s;
  }

  label {
    &.form-label {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.03em;
    }
  }

  .form-wrapper {
    h3 {
      &#sign-up-referrals-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.03em;
        color: $bits-palette-dark-grey-v4;
      }
    }

    ul {
      &#sign-up-referrals-ul {
        padding-left: 0px !important;
      }
    }
  }

  #sign-up-about-your-data-container {
    .form-wrapper {
      padding: 23px 37px;

      p,
      span {
        &#sign-up-about-your-data-paragraph {
          font-size: 20px;
          max-width: 340px;
          line-height: 23px;
          color: $bits-color-total-black;
          margin-bottom: 60px;
          letter-spacing: -0.03em;
        }
        &.sign-up-about-your-data-item-label {
          font-size: 20px;
          line-height: 23px;
          letter-spacing: -0.03em;
          color: $bits-color-total-black;
        }
      }

      ul {
        padding-left: 27px;

        li {
          font-size: 26px;
          list-style: disc;
        }
      }
    }

    #sign-up-about-your-data-group {
      margin-top: 80px;

      label {
        max-width: 315px;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.03em;
        color: $bits-color-total-black;

        a {
          color: $bits-color-total-black;
          &:hover {
            color: $bits-color-total-black !important;
          }
        }
      }

      input {
        &#sign-up-about-your-data-terms-and-conditions {
          width: 20px;
          height: 20px;
          background-color: #d9d9d9 !important;
          border-radius: 4px;
          margin-right: 13px;
          border: 0px;
          cursor: pointer;

          &:focus {
            box-shadow: none;
          }
          &:active {
            filter: brightness(100%);
          }
        }
        &.form-check-input {
          &:checked {
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("../../../assets/cassetteUI/icons/check-bold.svg");
          }
        }

        input {
          &#sign-up-about-your-data-submit {
            margin-top: 60px;
            width: 100% !important;
          }
        }
      }
    }

    .disabled {
      color: $bits-color-disabled-grey !important;
    }

    .enabled {
      color: $bits-color-total-black;
    }
  }

  .phoneNumber-note {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    img {
      width: 26px;
      height: auto;
      margin-right: 5px;
    }
    p {
      font-size: 17px;
      line-height: 22px;
      color: #9797d0;
      margin-top: 2px;
      margin-bottom: 0px;
    }
  }

  a {
    &#login-forgot-password {
      font-size: 16px;
      line-height: 23px;
      text-decoration: none;
      color: $bits-color-black;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.02em;
    }
  }

  h4 {
    &#reset-password-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: $bits-palette-dark-grey-v4;
    }
  }
  p {
    &#reset-password-paragraph {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.03em;
      color: $bits-palette-dark-grey-v4;
    }
  }

  .special-character {
    span {
      margin-left: 30px;
      font-size: 17px;
      position: relative;
      &.special-character-false {
        color: #9797d0;
        &:before {
          content: "";
          position: absolute;
          top: -2px;
          left: -30px;
          background-image: url("../../../assets/cassetteUI/icons/alert-circle.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: 22px;
          height: 22px;
        }
      }
      &.special-character-true {
        color: #2d8847;
        &:before {
          content: "";
          position: absolute;
          top: -3.5px;
          left: -31.5px;
          background-image: url("../../../assets/cassetteUI/icons/CheckCircle.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  #reset-password-container,
  #login-container {
    margin-bottom: 200px;
  }

  li {
    &.select-referral {
      cursor: pointer;
      & span {
        &.selected {
          text-decoration: underline;
        }
      }
    }
  }

  #important-information {
    p {
      &#important-information-subtitle {
        font-size: 20px;
        max-width: 340px;
        line-height: 23px;
        color: $bits-color-total-black;
        margin-bottom: 60px;
        letter-spacing: -0.03em;
      }
    }
    .form-wrapper {
      padding: 23px 37px;

      ul {
        margin-bottom: 50px;
        li {
          font-size: 26px;
          list-style: disc;
        }
      }
    }
  }
}

.password-group {
  .input-group {
    display: block !important;
  }
}

/* All Inputs Error */
.input-error {
  position: relative;
  display: flex;
  align-items: center;
  height: 61px;
  width: 100%;
  outline: 4px solid $bits-palette-red-ffb5b5-v4;
  border-radius: 18px !important;

  &:before {
    content: "";
    background-image: url("../../../assets/cassetteUI/icons/alert.svg");
    position: absolute;
    right: 10px;
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
  }
}
.input-error-message {
  margin-top: 12px !important;
  font-size: 18px !important;
  line-height: 23px !important;
  letter-spacing: -0.03em !important;
  color: $bits-palette-red-ff9898-v4 !important;
  font-weight: 700;

  a {
    text-decoration: none;
    color: $bits-color-total-black;
  }
}
.not-found {
  height: 50vh;
  text-align: center;

  h2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  P {
    margin-bottom: 5px;
    a {
      color: $bits-color-black;
    }
  }

  .mobile-logo {
    display: none;
  }
}

@media (max-width: 991px) {
  .plans-header {
    text-align: center !important;
  }
}

@media (max-width: 575px) {
  .cassette {
    #sign-up-about-your-data-container {
      .form-wrapper {
        padding: 23px 15px !important;
      }
    }

    .unAuth-card {
      border-radius: 0px;
      border: 0px;
      margin-bottom: 50px;
      background-color: $bits-palette-transparent-v4;

      .form-wrapper {
        padding: 40px 20px 33px 20px;

        .form-group {
          margin-bottom: 90px;
          height: 100%;
        }
      }
    }

    .unAuth-card {
      min-height: 400px;
      margin-bottom: 0px;
      .signup-header {
        padding-top: 20px;
      }
    }
    .termsConditions {
      max-width: 100%;
      border: 0px solid #cacaca;
      border-radius: 0px;
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .plans-header {
    margin: 50px 0px !important;
  }

  .not-found {
    h2 {
      margin-top: 80px;
    }
    .mobile-logo {
      display: block;
    }
    .desktop-bits-icon {
      display: none;
    }
  }
}

.skip-button {
  width: 350px;
  max-width: 100%;
  a {
    color: $bits-color-total-black;
    font-weight: 700;
    font-size: 24px;
    margin-left: 60px;
  }
  margin-top: 100px;
}

@media (max-width: 1200px) {
  .skip-button {
    a {
      margin-left: 0;
    }
    text-align: center;
    margin: 0 auto;
  }
}

@media (max-width: 991px) {
  .skip-button {
    a {
      margin-left: 0;
    }
    text-align: center;
    margin: 0 auto;
  }
}