@import "../../sass/variables.scss";

.checkout {
  .checkout-header {
    .checkout-title {
      font-size: $bits-font-size-massive;
      font-weight: 600;
      @media only screen and (max-width: 767px) {
        font-size: $bits-font-size-normal-text;
        text-align: right;
      }
    }
  }

  a {
    text-decoration: none;
  }

  button {
    width: 100%;
    margin: 10px 0;
  }

  .content-container {
    min-height: 350px;
  }
}
