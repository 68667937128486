@import "../../../../sass/variables.scss";

.cassette {
  .layout-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: -570px;
      left: 0px;
      height: 1700px;
      width: 100%;
      max-width: 100%;
      background-image: url("../../../../assets/cassetteUI/images/signup-bg.webp");
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;
    }
  }

  .subscriptions-layout {
    background: transparent !important;
  }

  .loggedOut {
    .spinner {
      .spinner-grow {
        background-color: white;
      }
    }
  }
  .welcome-header {
    max-width: 530px;
    height: 30px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .bits-back-button {
      position: absolute;
      text-align: left;
      color: $bits-color-total-black !important;
      text-decoration: none;

      &:hover {
        color: $bits-color-total-black !important;
      }

      span {
        color: $bits-color-total-black;
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
  .welcome-layout {
    width: 572px !important;
    max-width: 100% !important;
    background-color: transparent !important;
    border-radius: 0px !important;
    padding: 0px !important;
    margin: 0 auto !important;
    position: inherit !important;
  }
  .trust-pilot {
    max-width: 100%;
    background: $bits-palette-grey-f9f9f9a1-v4;
    border-radius: 24px;
    margin: 70px auto 326px auto;
    padding: 30px 30px 0px;
  }

  .important-info-link {
    position: absolute;
    top: -33px;
    left: 0;
    right: 0;
    max-width: 200px;
    padding: 0px;
    margin: 0 auto;
    color: $bits-palette-dark-blue-0b51c1-v4;

    &:hover {
      color: $bits-palette-dark-blue-0b51c1-v4;
    }
  }

  .important-info-modal {
    text-align: center;
    ul li {
      list-style: disc;
      font-size: 22px;
      p {
        font-size: 18px;
        text-align: left;
      }
    }

    .btn-close {
      position: absolute;
      right: 15px;
    }

    .modal-title {
      margin: 0 auto;
    }

    .modal-footer {
      button {
        font-weight: 600;
        font-size: 18px;
        width: 110px;
        border: 0;
        background-color: $bits-color-total-black;
        color: $bits-color-white;
      }
    }
  }
}
@media (max-width: 767px) {
  .important-info-link {
    top: -28px !important;
  }
}
@media (max-width: 575px) {
  .cassette {
    .layout-wrapper {
      .welcome-header {
        margin-top: 30px;
        .bits-back-button {
          margin: 20px 0px 20px 20px !important;
        }
      }

      .welcome-layout,
      #login-container,
      #reset-password-container {
        border-radius: 10px 10px 0 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
    }

    .trust-pilot {
      background: $bits-palette-transparent-v4;
      border: 0px;
      margin-bottom: 90px;
      padding: 0px;
    }
  }
}
