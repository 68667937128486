#score-apply {
  .unAuth-card {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #979797;
    border-radius: 24px
  }

  .bottom-placeholder {
    margin-bottom: 200px;
  }
}
