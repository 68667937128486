@import "../../../sass/variables";

.store-product-details-wrapper {
  .bits-loading-wrapper {
    margin-top: 60px;
  }
  .product-images-wrapper {
    display: flex;
  }
  .product-name {
    font-size: $bits-font-size-largest;
  }

  .product-description {
    ul li {
      list-style-type: circle;
    }
  }

  .product-price {
    font-size: $bits-font-size-big;
    color: $bits-color-black;
    letter-spacing: -0.5px;
    line-height: 150%;
  }

  .product-shipping {
    font-size: $bits-font-size-large;
    font-weight: 400;
    color: $bits-color-blue;
  }

  h5 {
    font-size: $bits-font-size-normal-text;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: -0.5px;
  }

  .product-variants {
    .btn {
      padding: 5px 20px;
      font-size: $bits-font-size-normal-text;
      margin-left: 10px;
      margin-bottom: 5px;
    }
  }

  .product-images {
    .product-main-image {
      img {
        border-radius: 10px;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .control-arrow,
      .control-arrow-next {
        display: flex;
        align-items: center;
        opacity: 1;
        i {
          color: $bits-color-black;
          font-size: $bits-font-size-huge;
        }
      }

      .control-arrow:before {
        margin: 0;
      }

      .control-arrow-next {
        right: 0;
      }

      .control-arrow:hover {
        background: transparent;
      }

      .product-carousel-image {
        max-width: 100%;
        width: 100%;
        height: 340px;
        vertical-align: middle;
      }
    }

    .product-thumbnail-container {
      width: 120px;
      height: auto;
      min-height: 70px;
      border-radius: 5px;
      border: 2px solid $bits-color-white;
      overflow: hidden;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }

      &.active {
        border: 2px solid $bits-color-blue;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .product-name {
      font-size: $bits-font-size-normal-text;
    }

    .product-price {
      font-size: $bits-font-size-huge;
      color: $bits-color-black;
    }

    .product-description {
      font-size: $bits-font-size-normal-ui;
    }
  }

  @media only screen and (max-width: 992px) {
    .product-images-wrapper {
      display: block !important;
    }

    .product-thumbnails {
      display: flex;
      padding: 0 1em;
      margin-top: 0.5em;
      overflow-x: auto;
    }

    .product-thumbnail-container {
      min-width: 120px;
    }
  }
}
@media (max-width: 600px) {
  .uk-mobile-product-details-wrapper {
    margin-top: 50px;
  }
}
