@import "./variables.scss";

$btn-hover-change: 3;

.btn {
  // General rules
  border-radius: 50px;
  font-weight: 500;
  letter-spacing: -0.5px;
  outline: none;

  &:not(.rounded-pill) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  // Variants
  &.btn-primary {
    background-color: $bits-color-blue;
    color: $bits-color-white;
    padding: 10px;
    &:hover {
      color: $bits-color-white;
      background-color: darken($bits-color-blue, $btn-hover-change);
    }
    &:active,
    &:focus {
      color: $bits-color-white;
    }
  }

  &.btn-secondary {
    background-color: $bits-color-grey;
    color: $bits-color-black;

    &:hover {
      color: $bits-color-black;
      background-color: darken($bits-color-grey, $btn-hover-change);
    }
  }

  &.btn-success {
    background-color: $bits-color-green;
    color: $bits-color-white;

    padding: 10px;
    font-size: $bits-font-size-normal-ui;

    &:hover {
      color: $bits-color-white;
      background-color: darken($bits-color-green, $btn-hover-change);
    }
  }

  &.btn-warning {
    background-color: $bits-color-yellow;
    color: $bits-color-black;
    padding: 10px;
    font-size: $bits-font-size-normal-ui;

    &:hover {
      color: $bits-color-black;
      background-color: darken($bits-color-yellow, $btn-hover-change);
    }
  }

  &.btn-danger {
    background-color: $bits-color-red;
    color: $bits-color-white;
    padding: 10px;

    &:hover {
      color: $bits-color-white;
      background-color: darken($bits-color-red, $btn-hover-change);
    }
  }

  &.btn-dark {
    background-color: $bits-color-black;
    color: $bits-color-white;

    &:hover {
      color: $bits-color-white;
      background-color: darken($bits-color-black, $btn-hover-change);
    }
  }

  &.btn-default {
    background-color: $bits-color-black;
    color: $bits-color-white;

    &:hover,
    &:active,
    &:focus {
      color: $bits-color-white;
      background-color: darken($bits-color-black, $btn-hover-change);
    }
  }

  &.btn-white {
    background-color: $bits-color-white;
    color: $bits-color-blue;
    border-color: $bits-color-blue;

    &:hover {
      color: $bits-color-white;
      background-color: darken($bits-color-blue, $btn-hover-change);
    }
  }

  &.btn-link {
    color: $bits-color-blue;
    font-size: 20px;

    &:hover {
      color: $bits-color-light-blue;
    }
  }

  // Outline variants
  &.btn-outline-dark {
    color: $bits-color-black;
    padding: 10px;
    &:hover {
      color: $bits-color-white;
    }

    &:active {
      background-color: $bits-color-black;
      color: $bits-color-white;
    }
  }

  &.btn-outline-primary {
    color: $bits-color-blue;
    padding: 10px;
    &:hover,
    &:active,
    &:focus:hover {
      color: $bits-color-white;
    }
    &:focus {
      color: $bits-color-blue;
    }
  }

  // Sizes
  &.btn-lg {
    font-size: 26px;
    line-height: 50px;
  }

  // States
  &:disabled {
    background-color: $bits-color-disabled-grey;
    border-color: $bits-color-disabled-grey;
    color: $bits-color-white;
    opacity: 1;
  }

  &:hover,
  &:active,
  &:focus {
    color: inherit;
  }

  &:active,
  &:focus {
    box-shadow: none;
  }

  &.rectangle {
    border-radius: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    &.btn-lg {
      font-size: 14px;
      line-height: 24px;
    }

    &.btn-link {
      font-size: 16px;
    }
  }
}
