@import "../../../sass/variables.scss";

#sign-up-subscription-plans-reports {
  color: $bits-color-black;
}

@media only screen and (max-width: 767px) { 

 #sign-up-subscription-plans-reports-wrapper {
  border-bottom: 1px solid $bits-color-input-border-light;
 }
 #sign-up-subscription-plans-reports {
   margin-bottom: 15px;
   display: inline-block;
 }
}