/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + ".eot");
    src: url($font-file + ".eot?#iefix") format("embedded-opentype"), url($font-file + ".woff") format("woff"),
      url($font-file + ".ttf") format("truetype"), url($font-file + ".svg#" + $font-family) format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

$bits-font-poppins: "Poppins";
$bits-font-fallback: "Helvetica Neue";
$bits-font: "Poppins", $bits-font-fallback;

@import url("https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&display=swap");
$bits-font-covered-grace: "Covered By Your Grace", cursive;

/* Bits Store UK V4.0 - single font type for the entire web App signup + dashboard Haffer */
@font-face {
  font-family: "haffer sq regular";
  font-style: normal;
  font-weight: 400;
  src: local("haffer sq regular"), url("../assets/cassetteUI/fonts/HafferSQ-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "haffer sq bold";
  font-style: normal;
  font-weight: 700;
  src: local("haffer sq bold"), url("../assets/cassetteUI/fonts/HafferSQ-Bold.woff2") format("woff2");
}

$bits-fontHafferSQ-regular: "haffer sq regular";
$bits-fontHafferSQ-bold: "haffer sq bold";
$bits-font-haffer-fallback: "sans-serif";
$bits-font-hafferSQ-regular: $bits-fontHafferSQ-regular, $bits-font-haffer-fallback;
$bits-font-hafferSQ-bold: $bits-fontHafferSQ-bold, $bits-font-haffer-fallback;

$bits-font-size-tiny: 14px;
$bits-font-size-small: 16px;
$bits-font-size-normal-ui: 18px;
$bits-font-size-normal-text: 20px;
$bits-font-size-fair: 22px;
$bits-font-size-large: 24px;
$bits-font-size-modal-subheader: 25px;
$bits-font-size-larger: 26px;
$bits-font-size-largest: 28px;
$bits-font-size-big: 30px;
$bits-font-size-big-button: 35px;
$bits-font-size-huge: 38px;
$bits-font-size-modal-header: 48px;
$bits-font-size-huger: 50px;
$bits-font-size-massive: 52px;
$bits-font-size-banner: 55px;
